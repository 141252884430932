<template>
<div class="w-900 mx-auto mb-10">
    <!-- <img src="@/assets/csr_t1.jpg"> -->
    <div class="flex mt-4 mb-8">
        <h1 class="text-5xl font-light text-bgnav mr-3">永續報告書</h1>
        <p class="text-3xl font-thin text-gray-500 mt-4">Sustainability Report</p>
    </div>

    <p>如果您對我們的永續報告書有任何建議，歡迎您隨時與我們聯繫！</p>
    <p>服務信箱：<a class="hover:underline" href="mailto:csr@ttfb.com" target="_blank">csr@ttfb.com</a></p>
    <!-- --->
    <table class="w-900 mt-4 mb-9 text-xs table-fixed">
        <thead class="bg-gray-500 text-white font-bold">
            <tr class="h-8">
                <th class="w-2/12">日 期</th>
                <th class="w-8/12">標 題</th>
                <th class="w-2/12">附 檔</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(List, index) in Lists" :key="index" v-once class="h-8 even:bg-bgtable">
                <td class="text-center">{{ List.date }}</td>
                <td class="pl-12">{{ List.text }}</td>
                <td class="text-center">
                    <a :href="List.url" target="_blink">
                        <DownloadIcon class="h-5 text-red-700 mx-auto cursor-pointer transition duration-300 ease-in-out transform hover:translate-y-1" />
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
    <!-- --->
    <img src="@/assets/csr_t2.jpg">
    <p>瓦城泰統集團為實踐企業社會責任，並促成經濟、環境及社會之進步，以達永續發展之目標，集團訂有「企業社會責任實務守則」，由總經理室負責企業社會責任政策或制度之提出及執行，隨時檢討實施成效情形，並定期向董事會報告。本公司參考全球永續性報告協會(Global Reporting Initiative, GRI )所出版的最新GRI準則，進行利害關係人與重大性議題的鑑別，藉此準確掌握利害關係人關切的重大環境、社會及公司治理相關議題，評估管理方針之有效性，做為公司發展永續經營策略的重要依據。</p>
    <div class="flex justify-between mt-4 mb-6">
        <div>
            <h1 class="mb-2">利害關係人鑑別與溝通</h1>
            <ol class="list-decimal list-inside ml-4 my-6 space-y-2 text-xs">
                <li>參照GRI Standards與GRI發布之特殊行業參考指標執行利害關係人鑑別</li>
                <li>以問卷方式調查利害關係人關注議題</li>
                <li>檢視與分析重大議題</li>
                <li>確認重大議題對公司的衝擊</li>
                <li>揭露執行成效</li>
            </ol>
            <p>本公司相關重大議題內容與執行成效，請參閱本公司永續報告書。</p>
        </div>
        <div class="flex-none">
            <img class="h-52 mr-16" src="@/assets/csr_t3.png">
        </div>
    </div>
    <h1>聯絡我們</h1>
    <table class="w-900 table-fixed text-xs mt-2">
        <thead class="bg-bgnav h-8">
            <tr class="text-white font-semibold">
                <td class="w-2/12 pl-4">利害關係人</td>
                <td class="w-3/12">服務專線</td>
                <td class="w-4/12">服務信箱</td>
                <td class="w-3/12">聯 絡 人</td>
            </tr>
        </thead>
        <tbody>
            <tr class="h-8">
                <td class="pl-4 font-bold">同仁</td>
                <td>02-8227-1280 #130</td>
                <td><a class="underline" href="mailto:hrservice@ttfb.com" target="_blank">hrservice@ttfb.com</a></td>
                <td>黃馨瑩資深經理</td>
            </tr>
            <tr class="h-8 bg-bgtable">
                <td class="pl-4 font-bold">供應商</td>
                <td>02-8227-1280 #530</td>
                <td><a class="underline" href="mailto:purchase@ttfb.com" target="_blank">purchase@ttfb.com</a></td>
                <td>陳怡杏經理</td>
            </tr>
            <tr class="h-8">
                <td class="pl-4 font-bold">投資人 / 媒體</td>
                <td>02-2248-1022</td>
                <td><a class="underline" href="mailto:IR@ttfb.com" target="_blank">IR@ttfb.com</a></td>
                <td>黃婉琳經理</td>
            </tr>
            <tr class="h-8 bg-bgtable">
                <td class="pl-4 font-bold">顧客</td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr class="h-8">
                <td class="pl-12 font-bold">瓦城</td>
                <td>0800-086680</td>
                <td><a class="underline" href="mailto:service@thaitown.com.tw" target="_blank">service@thaitown.com.tw</a></td>
                <td></td>
            </tr>
            <tr class="h-8 bg-bgtable">
                <td class="pl-12 font-bold">非常泰</td>
                <td>0800-086680</td>
                <td><a class="underline" href="mailto:service@verythai.com.tw" target="_blank">service@verythai.com.tw</a></td>
                <td></td>
            </tr>
            <tr class="h-8">
                <td class="pl-12 font-bold">大心</td>
                <td>0800-086680</td>
                <td><a class="underline" href="mailto:service@bheartnoodles.com.tw" target="_blank">service@bheartnoodles.com.tw</a></td>
                <td></td>
            </tr>
            <tr class="h-8 bg-bgtable">
                <td class="pl-12 font-bold">月月 泰BBQ</td>
                <td>0800-086680</td>
                <td><a class="underline" href="mailto:service@ttfb.com" target="_blank">service@ttfb.com</a></td>
                <td></td>
            </tr>
            <tr class="h-8">
                <td class="pl-12 font-bold">YABI</td>
                <td>0800-086680</td>
                <td><a class="underline" href="mailto:service@ttfb.com" target="_blank">service@ttfb.com</a></td>
                <td></td>
            </tr>
            <tr class="h-8 bg-bgtable">
                <td class="pl-12 font-bold">時時香</td>
                <td>0800-333677</td>
                <td><a class="underline" href="mailto:service@ricebar.com.tw" target="_blank">service@ricebar.com.tw</a></td>
                <td></td>
            </tr>
            <tr class="h-8 ">
                <td class="pl-12 font-bold">1010湘</td>
                <td>0800-221010</td>
                <td><a class="underline" href="mailto:service@1010restaurant.com" target="_blank">service@1010restaurant.com</a></td>
                <td></td>
            </tr>
            <tr class="h-8 bg-bgtable">
                <td class="pl-4 font-bold">社區</td>
                <td>02-8227-1280 #883</td>
                <td><a class="underline" href="mailto:csr@ttfb.com" target="_blank">csr@ttfb.com</a></td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>
</template>

<script>
import { DownloadIcon } from '@heroicons/vue/outline'
export default {
    components: { DownloadIcon },

    setup() {
        const Lists = [
            {
                date: '2023-12-29',
                text: '社會責任情形 - 同仁照護',
                url: '../upfile/sr20231229e.pdf'
            },
            {
                date: '2023-12-29',
                text: '社會責任情形 - 社會關懷',
                url: '../upfile/sr20231229d.pdf'
            },
            {
                date: '2023-12-29',
                text: '社會責任情形 - 環境永續',
                url: '../upfile/sr20231229c.pdf'
            },
            {
                date: '2023-12-29',
                text: '退休制度與實施情形',
                url: '../upfile/sr20231229b.pdf'
            },
            {
                date: '2023-12-29',
                text: '履行永續發展情形',
                url: '../upfile/sr20231229ab.pdf'
            },
            {
                date: '2023-09-28',
                text: '2022年永續報告書',
                url: '../upfile/csr2022i.pdf'
            },
            {
                date: '2022-12-30',
                text: '履行永續發展情形',
                url: '../upfile/csr2022e.pdf'
            },
            {
                date: '2022-12-30',
                text: '退休制度與實施情形',
                url: '../upfile/csr2022d.pdf'
            },
            {
                date: '2022-12-30',
                text: '永續發展情形-同仁照護',
                url: '../upfile/csr2022c.pdf'
            },
            {
                date: '2022-12-30',
                text: '永續發展情形-社會關懷',
                url: '../upfile/csr2022b.pdf'
            },
            {
                date: '2022-12-30',
                text: '永續發展情形-環境永續',
                url: '../upfile/csr2022a.pdf'
            },
            {
                date: '2021-12-30',
                text: '履行社會責任情形',
                url: '../upfile/cg2021_11012_7.pdf'
            },
            {
                date: '2021-12-30',
                text: '退休制度與實施情形',
                url: '../upfile/cg2021_11012_8.pdf'
            },
            {
                date: '2021-12-30',
                text: '社會責任情形 - 環境永續',
                url: '../upfile/cg2021_11012_4.pdf'
            },
            {
                date: '2021-12-30',
                text: '社會責任情形 - 社會關懷',
                url: '../upfile/cg2021_11012_5.pdf'
            },
            {
                date: '2021-12-30',
                text: '社會責任情形 - 同仁照護',
                url: '../upfile/cg2021_11012_6.pdf'
            },
            {
                date: '2021-09-30',
                text: '2021年永續報告書',
                url: '../upfile/csr2021.pdf'
            },
            {
                date: '2021-09-30',
                text: '2020年企業社會責任報告書',
                url: './upfile/ctxt_582.pdf'
            },
            {
                date: '2020-12-28',
                text: '社會責任情形 - 同仁照護',
                url: './upfile/ctxt_553.pdf'
            },
            {
                date: '2020-12-28',
                text: '社會責任情形 - 社會關懷',
                url: './upfile/ctxt_552.pdf'
            },
            {
                date: '2020-12-28',
                text: '社會責任情形 - 環境永續',
                url: './upfile/ctxt_551.pdf'
            },
            {
                date: '2020-12-28',
                text: '退休制度與實施情形',
                url: './upfile/ctxt_550.pdf'
            },
            {
                date: '2020-12-28',
                text: '履行社會責任情形',
                url: './upfile/ctxt_549.pdf'
            },
            {
                date: '2020-09-30',
                text: '2019年企業社會責任報告書',
                url: './upfile/ctxt_527.pdf'
            },
            {
                date: '2019-12-31',
                text: '社會責任情形 - 同仁照護',
                url: './upfile/ctxt_508.pdf'
            },
            {
                date: '2019-12-31',
                text: '社會責任情形 - 社會關懷',
                url: './upfile/ctxt_507.pdf'
            },
            {
                date: '2019-12-31',
                text: '社會責任情形 - 環境永續',
                url: './upfile/ctxt_506.pdf'
            },
            {
                date: '2019-12-31',
                text: '退休制度與實施情形',
                url: './upfile/ctxt_505.pdf'
            },
            {
                date: '2019-12-31',
                text: '履行社會責任情形',
                url: './upfile/ctxt_504.pdf'
            },
            {
                date: '2019-09-30',
                text: '2018年企業社會責任報告書',
                url: './upfile/ctxt_491.pdf'
            },
            {
                date: '2018-12-31',
                text: '社會責任情形 - 同仁照護',
                url: './upfile/ctxt_474.pdf'
            },
            {
                date: '2018-12-31',
                text: '社會責任情形 - 社會關懷',
                url: './upfile/ctxt_473.pdf'
            },
            {
                date: '2018-12-31',
                text: '社會責任情形 - 環境永續',
                url: './upfile/ctxt_472.pdf'
            },
            {
                date: '2018-12-31',
                text: '退休制度與實施情形',
                url: './upfile/ctxt_471.pdf'
            },
            {
                date: '2018-12-31',
                text: '履行社會責任情形',
                url: './upfile/ctxt_470.pdf'
            },
            {
                date: '2018-12-31',
                text: '利害關係人關注議題及主要溝通管道',
                url: './upfile/ctxt_469.pdf'
            },
            {
                date: '2018-12-28',
                text: '2017年企業社會責任報告書',
                url: './upfile/ctxt_449.pdf'
            },
            {
                date: '2017-12-28',
                text: '2016年企業社會責任報告書',
                url: './upfile/ctxt_415.pdf'
            },
            {
                date: '2016-12-30',
                text: '2015年企業社會責任報告書',
                url: './upfile/ctxt_391.pdf'
            },
            {
                date: '2015-12-30',
                text: '2014年企業社會責任報告書',
                url: './upfile/ctxt_350.pdf'
            },
        ]

        return { Lists }
    }
}
</script>